<template>
  <div style="background-color: #f9f9fb;">
    <Myhead :nav="1"></Myhead>
    <FirstHeader :title="Language.ourServices.ourServices" img-url="ourServices"/>
    <div class="item">
      <div class="itembox">
        <a :class="nav == 1 ? 'item-checked':''" @click="changenav(1)" href='#service1'>{{ Language.ourServices.certificate_services }}</a>
        <a :class="nav == 2 ? 'item-checked':''" @click="changenav(2)" href='#service2'>{{ Language.ourServices.test_service }}</a>
        <a :class="nav == 3 ? 'item-checked':''" @click="changenav(3)" href='#service3'>{{ Language.ourServices.quality_control }}</a>
        <a :class="nav == 4 ? 'item-checked':''" @click="changenav(4)" href='#service4'>{{ Language.ourServices.technical_advice }}</a>
        <a :class="nav == 5 ? 'item-checked':''" @click="changenav(5)" href='#service5'>{{ Language.ourServices.E_commerce_services }}</a>
      </div>
    </div>
    <div>
      <!--认证服务-->
      <div class="service" id="service1">
        <div class="service-text bg">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.certificate_services }}</div>
            <div>{{ Language.ourServices.msg1 }}</div>
            <div>{{ Language.ourServices.msg2 }}</div>
            <div>{{ Language.ourServices.msg3 }}</div>
            <div>{{ Language.ourServices.msg4 }}</div>
            <div>{{ Language.ourServices.msg5 }}
            </div>
            <div class="btn-learnMore" @click="tocertificateService()">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
        <div class="service-img">
          <img class="imgStyle" src="../assets/img/ourServices/service1.jpg" alt="认证服务"/>
        </div>
      </div>
      <!--测试服务-->
      <div class="service" id="service2">
        <div class="service-text show">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.test_service }}</div>
            <div style="line-height: 30px">{{ Language.ourServices.msg6 }}</div>
            <div class="btn-learnMore" @click="toserviceProducts()">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
        <div class="service-img">
          <img class="imgStyle" src="../assets/img/ourServices/service2.jpg" alt="测试服务"/>
        </div>
        <div class="service-text hide">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.test_service }}</div>
            <div style="line-height: 30px">{{ Language.ourServices.msg6 }}</div>
            <div class="btn-learnMore" @click="toserviceProducts()">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
      </div>
      <!--质量管理-->
      <div class="service" id="service3">
        <div class="service-text bg">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.quality_control }}</div>
            <div style="line-height: 30px;font-size: 14px; color: var(--text-color);">
                {{ Language.ourServices.msg7  + Language.ourServices.msg8}}
<!--{{ Language.ourServices.msg8 }}-->
            </div>
            <div class="btn-learnMore" @click="tomanagementSystem()">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
        <div class="service-img">
          <img class="imgStyle" src="../assets/img/ourServices/service3.jpg" alt="质量管理"/>
        </div>
      </div>
      <!--技术咨询-->
      <div class="service" id="service4">
        <div class="service-text show">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.technical_advice }}</div>
            <div style="line-height: 30px">{{ Language.ourServices.msg9 }}</div>
            <div class="btn-learnMore" @click="totechnicalAdvice">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
        <div class="service-img">
          <img class="imgStyle" src="../assets/img/ourServices/service4.jpg" alt="技术咨询"/>
        </div>
        <div class="service-text hide">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.technical_advice }}</div>
            <div style="line-height: 30px">{{ Language.ourServices.msg9 }}</div>
            <div class="btn-learnMore" @click="totechnicalAdvice">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
      </div>
      <!--电商服务-->
      <div class="service" id="service5">
        <div class="service-text bg">
          <div style="width: 643px">
            <div class="service-title">{{ Language.ourServices.E_commerce_services }}</div>
            <div style="line-height: 30px">{{ Language.ourServices.msg10 }}</div>
            <div class="btn-learnMore" @click="toEcommerce()">{{ Language.ourServices.Learn_more }}</div>
          </div>
        </div>
        <div class="service-img">
          <img class="imgStyle" src="../assets/img/ourServices/service5.jpg" alt="电商服务"/>
        </div>
      </div>
    </div>
    <baojia></baojia>
    <Myfoot></Myfoot>
  </div>
</template>
<script>
import FirstHeader from "@/component/firstHeader";
import Myhead from "@/component/myhead";
import Myfoot from "@/component/myfoot";
import baojia from "@/component/baojia";

export default {
  inject:['reload','L'],
  computed:{
    Language(){
      return this.L()
    }
  },
  components: {
    FirstHeader,
    Myhead,
    Myfoot,
    baojia
  },
  data() {
    return {
      nav: 1
    }
  },
  methods: {
    tocertificateService() {
      this.$router.push("/certification")
    },
    changenav(e) {
      this.nav = e
    },
    toserviceProducts(){
      this.$router.push("/products")
    },
    tomanagementSystem(){
      this.$router.push("/our-services/quality-control-management")
    },
    toEcommerce(){
      this.$router.push("/our-services/e-commerce")
    },
    tocontactUs(){
      this.$router.push("/contact")
    },
    totechnicalAdvice(){
      this.$router.push("/our-services/technical-consulting")
    }
  }
}
</script>
<style lang="scss" scoped>

.show{
  display: none !important;
}

.hide{
  display: flex !important;
}

.item {
  height: 80px;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  //padding: 0 20%;
  .itembox{
    padding: 0px 20px;
    width: 1200px;
    font-weight: bold;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  a {
    height: 80px;
    line-height: 80px;
    color: #000000;
  }

  .item-checked {
    color: var(--theme-color1);
    font-weight: bold;
    border-bottom: 2px solid var(--theme-color1);
  }
}

.service {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .service-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    //height: 400px;
    color: var(--text-color);

    .service-title {
      font-size: 42px;
      color: var(--title-color);
      margin-bottom: 20px;
    }

    div {
      margin-bottom: 8px;
    }
  }

  .bg {
    background-color: #f9f9fb;
  }

  .service-img {
    width: 50%;
    height: calc((50vw) / 2 * 1);

    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 0px) and (max-width: 1300px){
  .service{
    flex-wrap: wrap;
    .service-text{
      width: 100%;
      margin: 20px 0 ;
      padding: 0px 20px;
    }
    .service-img{
      width: 100%;
      height: auto;
    }
  }

  .show{
    display: flex !important;
  }

  .hide{
    display: none!important;
  }
}

</style>
